<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
             label-width="80px">
      <el-form-item label="服务类型" prop="name">
        <el-input v-model="dataForm.name" placeholder="服务类型"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" v-if="dataForm.updateTime" prop="updateTime">
        <el-input v-model="dataForm.updateTime" placeholder="更新时间" disabled></el-input>
      </el-form-item>
      <el-form-item label="显示地址" prop="showAddr">
        <el-radio v-model="dataForm.showAddr" :label="1">是</el-radio>
        <el-radio v-model="dataForm.showAddr" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="显示图片" prop="showImg">
        <el-radio v-model="dataForm.showImg" :label="1">是</el-radio>
        <el-radio v-model="dataForm.showImg" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="排序参数" prop="sort">
        <el-input-number v-model="dataForm.sort" :min="0" label="排序参数"></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import AddOrUpdateMixin from "@/mixins/AddOrUpdate";

export default {
  mixins: [AddOrUpdateMixin],
  data() {
    return {
      dataRule: {
        name: [
          {required: true, message: '服务类型不能为空', trigger: 'blur'}
        ],
        showAddr: [
          {required: true, message: '是否显示地址不能为空', trigger: 'blur'}
        ],
        showImg: [
          {required: true, message: '是否显示图片不能为空', trigger: 'blur'}
        ],
        sort: [
          {required: true, message: '排序参数不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(id) {
      this.doInit(id, `/wechat/servetype/info`, 'serveType').then((resp)=> {
        this.dataForm.updateTime = this.$utils.dateFormat('yyyy-MM-dd hh:mm', resp.updateTime)
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.doSubmit(`/wechat/servetype/${!this.dataForm.id ? 'save' : 'update'}`, {
        'id': this.dataForm.id || undefined,
        'name': this.dataForm.name,
        'showAddr': this.dataForm.showAddr,
        'showImg': this.dataForm.showImg,
        'sort': this.dataForm.sort
      })
    }
  }
}
</script>
